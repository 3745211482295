import React from 'react'
import { Text, View } from 'react-native'
import tailwind, { getColor } from 'tailwind-rn'
import ChangePassword from '../components/ChangePassword'
import { useAuthDispatch, useAuthState } from '../contexts/AuthContext'
import { Entypo } from '@expo/vector-icons'
import { AntDesign } from '@expo/vector-icons'
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler'
import useFetch from '../utils/useFetch'
import { BASE_URL } from '../utils/common'

export default function VinculosScreen({ navigation }) {
  const authState = useAuthState()
  const dispatch = useAuthDispatch()

  if (authState.isFirstLogin) return <ChangePassword />

  const { response, loading } = useFetch(
    `${BASE_URL}/api/colaboradoresVinculos`,
    {
      method: 'GET',
      headers: { Authorization: authState.userToken },
    }
  )

  const setVinculo = (codigoColaborador) => {
    dispatch({
      type: 'SET_VINCULO',
      codigoColaborador,
    })
    navigation.navigate('Master App')
  }

  console.log(authState)

  return (
    <View
      style={tailwind(
        'flex-col justify-center items-center h-full w-full px-4 bg-white'
      )}
    >
      <View style={tailwind('flex-col items-center w-full')}>
        <View>
          <Entypo
            name="link"
            size={40}
            color={getColor('white')}
            style={tailwind('text-center')}
          />
          <Text style={tailwind('my-4 text-xl font-bold text-gray-800')}>
            Selecione o Vínculo
          </Text>
        </View>

        {loading && (
          <Text style={tailwind('text-gray-800 text-lg')}>
            Carregando dados...
          </Text>
        )}

        {!loading && response && response.data && (
          <FlatList
            style={tailwind('w-full')}
            data={response.data}
            keyExtractor={(item) => item.codigo}
            renderItem={({ item }) => (
              <TouchableOpacity
                style={tailwind(
                  'mt-4 p-6 bg-gray-100 border border-gray-400 rounded-xl flex-row justify-between items-center'
                )}
                onPress={() => setVinculo(item.codigo)}
              >
                <View style={tailwind('flex-col')}>
                  <View>
                    <Text style={tailwind('uppercase text-gray-500 text-sm')}>
                      Lotação
                    </Text>
                    <Text style={tailwind('text-blue-800 font-bold text-lg')}>
                      {item.lotacao}
                    </Text>
                  </View>

                  <View>
                    <Text style={tailwind('uppercase text-gray-500 text-sm')}>
                      Cargo
                    </Text>
                    <Text style={tailwind('text-blue-800 font-bold text-lg')}>
                      {item.cargo}
                    </Text>
                  </View>

                  <View>
                    <Text style={tailwind('uppercase text-gray-500 text-sm')}>
                      Data de Admissão
                    </Text>
                    <Text style={tailwind('text-blue-800 font-bold text-lg')}>
                      {item.dataAdmissao}
                    </Text>
                  </View>

                  {!!item.dataDemissao && (
                    <View>
                      <Text style={tailwind('uppercase text-gray-500 text-sm')}>
                        Data de Demissão
                      </Text>
                      <Text style={tailwind('text-blue-800 font-bold text-lg')}>
                        {item.dataDemissao}
                      </Text>
                    </View>
                  )}
                </View>
                <View>
                  <AntDesign
                    name="arrowright"
                    size={24}
                    color={getColor('blue-800')}
                  />
                </View>
              </TouchableOpacity>
            )}
          />
        )}
      </View>
    </View>
  )
}
