import React from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import LoginScreen from './screens/Login'
import ContrachequesScreen from './screens/Contracheques'
import ContrachequeModalScreen from './screens/ContrachequeModalScreen'
import HomeScreen from './screens/Home'
import { useAuthState } from './contexts/AuthContext'
import FolhadePontoScreen from './screens/FolhadePonto'
import ResetPassword from './screens/ResetPassword'
import DadosPessoais from './screens/DadosPessoais'
import VinculosScreen from './screens/VinculosScreen'

const Stack = createStackNavigator()

export default function Navigation() {
  const authState = useAuthState()

  return (
    <NavigationContainer>
      <Stack.Navigator presentation="modal">
        {authState.userToken !== null && (
          <>
            <Stack.Screen
              name="Vinculos do Colaborador"
              //userToken={authState.userToken}
              options={{
                headerShown: false,
              }}
            >
              {(props) => (
                <VinculosScreen {...props} userToken={authState.userToken} />
              )}
            </Stack.Screen>
            <Stack.Screen
              name="Master App"
              options={{
                headerShown: false,
              }}
            >
              {(props) => (
                <HomeScreen {...props} userToken={authState.userToken} />
              )}
            </Stack.Screen>
            <Stack.Screen name="Dados Pessoais" userToken={authState.userToken}>
              {(props) => (
                <DadosPessoais {...props} userToken={authState.userToken} />
              )}
            </Stack.Screen>
            <Stack.Screen name="Contracheques" userToken={authState.userToken}>
              {(props) => (
                <ContrachequesScreen
                  {...props}
                  userToken={authState.userToken}
                />
              )}
            </Stack.Screen>
            <Stack.Screen name="Folha de Ponto">
              {(props) => (
                <FolhadePontoScreen
                  {...props}
                  userToken={authState.userToken}
                />
              )}
            </Stack.Screen>
            <Stack.Screen name="ContrachequeModalScreen">
              {(props) => (
                <ContrachequeModalScreen
                  {...props}
                  userToken={authState.userToken}
                />
              )}
            </Stack.Screen>
          </>
        )}
        {authState.userToken === null && (
          <>
            <Stack.Screen
              name="Entrar"
              component={LoginScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen name="Resetar Senha" component={ResetPassword} />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  )
}
