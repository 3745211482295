import React from 'react'
import { Text, TextInput, View, TouchableOpacity } from 'react-native'
import tailwind, { getColor } from 'tailwind-rn'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { RadioButton } from 'react-native-paper'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import Bancos from '../utils/bancos.json'
import { Picker } from '@react-native-community/picker'

export default function DadosBancarios() {
  const { register, setValue, watch, handleSubmit } = useForm()

  useEffect(() => {
    register('agencia')
    register('conta')
    register('banco')
    register('tipo')
  }, [])

  const onSubmit = async (data) => {
    console.log(data)
  }

  return (
    <View style={tailwind('flex items-center bg-white rounded-lg p-4')}>
      <MaterialCommunityIcons
        name="bank"
        size={35}
        color={getColor('blue-800')}
        style={tailwind('')}
      />
      <Text style={tailwind('text-lg font-bold text-blue-800')}>
        Dados Bancários
      </Text>
      <View style={tailwind('w-full my-2')}>
        <Text
          style={tailwind(
            'uppercase font-bold text-xs mb-1 top-0 text-gray-600'
          )}
        >
          Banco
        </Text>
        <View style={tailwind('bg-gray-300 w-full rounded-md text-blue-800')}>
          <Picker
            onValueChange={(value) => setValue('banco', value)}
            style={tailwind('text-blue-800')}
            selectedValue={watch('banco')}
          >
            {Bancos.map((data) => (
              <Picker.Item
                key={data.Code}
                label={data.Name}
                value={data.Code}
              />
            ))}
          </Picker>
        </View>
      </View>
      <View style={tailwind('w-full my-2')}>
        <Text
          style={tailwind(
            'uppercase font-bold text-xs mb-1 top-0 text-gray-600'
          )}
        >
          Agência (com dígito)
        </Text>
        <TextInput
          keyboardType="numeric"
          style={tailwind('bg-gray-300 w-full p-2 rounded-md text-blue-800')}
          onChangeText={(value) => setValue('agencia', value)}
        />
      </View>
      <View style={tailwind('w-full my-2')}>
        <Text
          style={tailwind(
            'uppercase font-bold text-xs mb-1 top-0 text-gray-600'
          )}
        >
          Conta (com dígito)
        </Text>
        <TextInput
          keyboardType="numeric"
          style={tailwind('bg-gray-300 w-full p-2 rounded-md text-blue-800')}
          onChangeText={(value) => setValue('conta', value)}
        />
      </View>
      <View style={tailwind('w-full my-2')}>
        <Text
          style={tailwind('uppercase font-bold text-xs mb-1 text-gray-600')}
        >
          Tipo de Conta
        </Text>
        <RadioButton.Group
          onValueChange={(newValue) => setValue('tipo', newValue)}
          value={watch('tipo')}
          color="blue"
        >
          <View style={tailwind('flex-col justify-between')}>
            <View style={tailwind('flex-row items-center')}>
              <RadioButton value="corrente" />
              <Text style={tailwind('uppercase text-gray-600 text-sm')}>
                Corrente
              </Text>
            </View>
            <View style={tailwind('flex-row items-center')}>
              <RadioButton value="poupança" />
              <Text style={tailwind('uppercase text-gray-600 text-sm')}>
                Poupança
              </Text>
            </View>
            <View style={tailwind('flex-row items-center')}>
              <RadioButton value="salário" />
              <Text style={tailwind('uppercase text-gray-600 text-sm')}>
                Salário
              </Text>
            </View>
          </View>
        </RadioButton.Group>
      </View>
      <View style={tailwind('w-full')}>
        <TouchableOpacity
          style={tailwind(
            'p-4 bg-blue-800 rounded-lg justify-center items-center mt-2'
          )}
          onPress={handleSubmit(onSubmit)}
        >
          <Text style={tailwind('text-white uppercase font-bold text-sm')}>
            Salvar
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}
