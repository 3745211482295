import React from 'react'
import { View, Text, TextInput, TouchableOpacity } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import tailwind, { getColor } from 'tailwind-rn'
import { useState } from 'react'
import {
  checkEmailConfirmationCode,
  generateEmailConfirmationCode,
  validateEmail,
} from '../utils/common'

export default function EmailRegister({ userToken, callback }) {
  const [email, setEmail] = useState('')
  const [activityIndicator, setActivityIndicator] = useState(false)
  const [confirmationCode, setConfirmationCode] = useState('')
  const [step, setStep] = useState(0)
  const [error, setError] = useState('')

  // Envia o código de confirmação para o email informado pelo usuário
  const sendEmailConfirmationCode = async () => {
    if (!validateEmail(email)) {
      setError('E-mail inválido')
      return
    }
    error && setError(null)
    setActivityIndicator(true)
    const success = await generateEmailConfirmationCode(email, userToken)
    setActivityIndicator(false)
    success && setStep(1)
    !success &&
      setError(
        'Não foi possível enviar o código de confirmação. Tente de novo ou entre em contato conosco'
      )
  }

  // Verifica o código de confirmação informado pelo usuário
  const verifyConfirmationCode = async () => {
    if (confirmationCode && confirmationCode.length !== 6) {
      setError('O código deve ter 6 dígitos')
      return
    }
    error && setError(null)
    setActivityIndicator(true)
    const success = await checkEmailConfirmationCode(
      confirmationCode,
      userToken
    )
    setActivityIndicator(false)
    console.log('before callback')
    success &&
      callback &&
      callback(email) &&
      console.log('Código de verificação CORRETO')
    !success && setError('Código de verificação incorreto.')
  }

  return (
    <>
      <View style={tailwind('flex justify-center items-center mb-4')}>
        {step === 0 && (
          <>
            <MaterialCommunityIcons
              name="email-plus"
              size={35}
              color={getColor('blue-800')}
              style={tailwind('')}
            />
            <Text style={tailwind('text-lg font-bold text-blue-800')}>
              Cadastro de e-mail
            </Text>
            <Text style={tailwind('text-center text-gray-700 w-56')}>
              Cadastre seu e-mail agora e resete sua senha quando necessário
            </Text>
          </>
        )}

        {step === 1 && (
          <>
            <MaterialCommunityIcons
              name="email-send"
              size={35}
              color={getColor('blue-800')}
              style={tailwind('')}
            />
            <Text style={tailwind('text-lg font-bold text-blue-800')}>
              Código enviado!
            </Text>
            <Text style={tailwind('text-center text-gray-700 w-56')}>
              Informe o código de confirmação enviado para {email}
            </Text>
          </>
        )}
      </View>
      {step === 0 && (
        <View>
          <TextInput
            autoCapitalize="none"
            keyboardType="email-address"
            placeholder="Digite seu e-mail aqui"
            style={tailwind(
              'bg-gray-300 w-full p-2 py-3 rounded-md text-blue-800'
            )}
            onChangeText={(text) => setEmail(text)}
          />
          {activityIndicator ? (
            <View
              style={tailwind(
                'p-2 bg-blue-800 rounded justify-center items-center mt-2'
              )}
            >
              <Text style={tailwind('text-white')}>Enviando...</Text>
            </View>
          ) : (
            <TouchableOpacity
              style={tailwind(
                'p-2 bg-blue-800 rounded justify-center items-center mt-2'
              )}
              onPress={sendEmailConfirmationCode}
            >
              <MaterialCommunityIcons
                name="arrow-right"
                size={15}
                color="white"
                style={{ marginLeft: 2 }}
              />
            </TouchableOpacity>
          )}
        </View>
      )}
      {step === 1 && (
        <View>
          <TextInput
            keyboardType="number-pad"
            placeholder="000000"
            maxLength={6}
            style={tailwind(
              'bg-gray-300 w-full p-2 rounded-md text-blue-800 font-bold text-lg tracking-widest text-center'
            )}
            onChangeText={(value) => setConfirmationCode(value)}
          />
          {activityIndicator ? (
            <View
              style={tailwind(
                'p-2 bg-blue-800 rounded justify-center items-center mt-2'
              )}
            >
              <Text style={tailwind('text-white')}>Confirmando...</Text>
            </View>
          ) : (
            <TouchableOpacity
              style={tailwind(
                'p-2 bg-blue-800 rounded justify-center items-center mt-2'
              )}
              onPress={verifyConfirmationCode}
            >
              <MaterialCommunityIcons
                name="arrow-right"
                size={15}
                color="white"
                style={{ marginLeft: 2 }}
              />
            </TouchableOpacity>
          )}
        </View>
      )}
      <Text style={tailwind('p-2 text-red-500 text-xs font-medium')}>
        {error}
      </Text>
    </>
  )
}
